<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="searchView">
			<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
				style="width: 200px;margin-right: 20px;" @keyup.enter.native="handelSearchClick"></el-input>
			<el-button type="primary" style="margin-right: 32px;" @click="handelSearchClick">查询</el-button>
			<div class="btnView">
				<el-button type="primary" v-if="$buttonAuthority('dianpu-pinpai-add')" @click="dialogTypeSetting_state = true;nodeData = brandData;addType = 4">新建品牌</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="view">
			<div class="tableView">
				<el-table :data="tableData">
					<el-table-column prop="brand.name" label="品牌名称">
					</el-table-column>
					<el-table-column prop="goodsCount" label="关联商品数量">
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<el-button type="text" size="small" v-if="$buttonAuthority('dianpu-pinpai-edit')" @click="handelEditClick(scope.row)">编辑</el-button>
							<el-button type="text" size="small" v-if="$buttonAuthority('dianpu-jifen-del')" @click="handelDeleteClick(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
	</div>
</template>

<script>
	import page from "../../../components/page.vue"
	import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
	export default{
		components:{
			page,
			dialogTypeSetting
		},
		data() {
			return{
				keyword:'',
				page: { //分页
					pageIndex: 1,
					pageSize: 10,
					total: 0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				dialogTypeSetting_state:false,
				brandData:null,  //品牌数据
				addType:4,  //4为新建
				nodeData:null,  //提交数据
			}
		},
		mounted() {
			this.getTableData()
			
		},
		methods:{
			//切换分页
			changePage(num) {
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//获取品牌
			getBrandData(){
				let params = {
					code:'breedtype'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.brandData = res.data;
					}
				})
			},
			//关闭弹框
			submit_dialogTypeSetting() {
				this.getTableData()
				this.dialogTypeSetting_state = false;
			},
			//获取列表
			getTableData(){
				let params = {
					pageIndex: this.page.pageIndex,
					pageSize: this.page.pageSize,
					keyword: this.keyword
				}
				this.$http.post("/cms/System/GetBrandList", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
						this.getBrandData()
					}
				})
			},
			handelSearchClick(){
				this.page.pageIndex = 1;
				this.getTableData();
			},
			//编辑
			handelEditClick(row){
				this.nodeData = row.brand;
				this.addType = 6;
				this.dialogTypeSetting_state = true;
			},
			//删除
			handelDeleteClick(row){
				this.$confirm('是否删除该品牌？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {
						id:row.brand.id
					}
					this.$http.get("/cms/System/DeleteSystemDiction", params).then(res => {
						if (res.code === 0) {
							this.getTableData()
							this.$message({
								type: 'success',
								message: '已删除'
							});
						}
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;
	
		.searchView {
			height: 80px;
			border-radius: 20px;
			margin-bottom: 16px;
			background: #fff;
			display: flex;
			align-items: center;
			padding: 0 20px;
	
			.search {
				display: flex;
				align-items: center;
				margin-right: 32px;
	
				.searchTitle {
					font-size: 14px;
					color: $fontColor;
				}
			}
	
			.btnView {
				flex: 1;
				text-align: right;
			}
		}
	
		.view {
			border-radius: 20px;
			overflow: hidden;
			flex: 1;
			background: #fff;
			display: flex;
			flex-direction: column;
	
			.tableView {
				flex: 1;
			}
		}
	}
</style>
